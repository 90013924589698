import moment from 'moment'
// 手机验证
export function rightPhoneNumber(mobile) {
  return /^1\d{10}$/gi.test(mobile)
}
// 获取链接中的参数
export function getUrlParam(item) {
  var svalue = location.hash.match(
    new RegExp('[?&]' + item + '=([^&]*)(&?)', 'i')
  )
  return svalue ? svalue[1] : svalue
}
// 获取多语言
export function getLangName(lang) {
  let LanguageType = ''
  if (lang === 'zh') {
    LanguageType = 'Chinese'
  } else if (lang === 'en') {
    LanguageType = 'English'
  } else if (lang === 'ft') {
    LanguageType = 'Traditional'
  } else if (lang === 'jp') {
    LanguageType = 'Japanese'
  }
  return LanguageType
}

export function getLocalTime(value) {
  return moment(value).local().format('YYYY-MM-DD HH:mm:ss')
}

export function IsreadSysNotice() {
  let obj = JSON.parse(localStorage.getItem('SysNotice'))
  const date = new Date()
  let m =
    date.getMonth() + 1 > 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
  let d = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
  let nowDate = m + d
  if (obj) {
    if (nowDate !== obj.data) {
      localStorage.setItem(
        'SysNotice',
        JSON.stringify({
          data: nowDate,
          timer: 1,
        })
      )
      return true
    }
  } else {
    localStorage.setItem(
      'SysNotice',
      JSON.stringify({
        data: nowDate,
        timer: 1,
      })
    )
    return true
  }
}

export function returnLang(lang, setLang = false) {
  let localeLang = ''
  switch (lang) {
    case 'Chinese':
      localeLang = 'zh'
      if (setLang) {
        this.$i18n.setLocaleMessage('zh', require('@/assets/lang/zh'))
      }
      break
    case 'Traditional':
      localeLang = 'ft'
      if (setLang) {
        this.$i18n.setLocaleMessage('ft', require('@/assets/lang/ft'))
      }
      break
    case 'English':
      localeLang = 'en'
      if (setLang) {
        this.$i18n.setLocaleMessage('en', require('@/assets/lang/en'))
      }
      break
    default:
      localeLang = 'jp'
      if (setLang) {
        this.$i18n.setLocaleMessage('jp', require('@/assets/lang/jp'))
      }
  }
  return localeLang
}

// 余额变更类型
export const balanceChangeTypeArr = [
  {
    Id: 'Upper',
    Name: '存款',
  },
  {
    Id: 'Lower',
    Name: '取款',
  },
  {
    Id: 'Bet',
    Name: '投注',
  },
  {
    Id: 'Win',
    Name: '中奖',
  },
  {
    Id: 'BalanceToFinance',
    Name: '余额转收益宝',
  },
  {
    Id: 'FinanceeToBalance',
    Name: '收益宝转余额',
  },
  {
    Id: 'CashProfit',
    Name: '下级提现收益',
  },
]
// 状态类型
export const TransferStatusArr = [
  {
    Id: 'Review',
    Name: '审核中',
  },
  {
    Id: 'Success',
    Name: '成功',
  },
  {
    Id: 'Fail',
    Name: '失败',
  },
]
