module.exports = {
    index: {
        grid: [{
                num: '12',
                text: 'Global Office',
            },
            {
                num: '100+',
                text: 'Trading products',
            },
            {
                num: '4',
                text: 'Authoritative supervision',
            },
            {
                num: '450+',
                text: 'Professional team',
            },
        ],
        product: [{
                title: 'Investment products',
                desc: 'CompanyNameJapanese To provide investors with more than 100 kinds of contract for differences trading products, including foreign exchange, precious metals, crude oil, stocks and indexes; All products can be traded on the same platform, bringing investors the best investment opportunities facing the global market.',
                imgUrl: require('@/assets/icon_forex-4.svg'),
            },
            {
                title: 'Capital security',
                desc: 'The customer capital and the company working capital are stored in separate bank accounts and are protected by the regulatory authorities。',
                imgUrl: require('@/assets/icon_lock.svg'),
            },
            {
                title: 'Global services',
                desc: 'CompanyNameJapanese We have 450 + professional staff all over the world, with more than 10 years of financial industry experience, providing professional localization services for all customers。',
                imgUrl: require('@/assets/icon_community.svg'),
            },
        ],
        experience: {
            title: 'Best trading experience',
            desc: 'We provide metatrader4 (MT4), one of the most widely used online trading platforms in the world. MT4 trading platform provides powerful chart analysis tools: more than 50 kinds of technical indicators and disk analysis tools. It is safe, reliable, easy to use and has the functional characteristics of high-level traders. It has become a standard platform for online trading. ',
            btn: 'Trade with MT4',
        },
        product2: [{
                title: 'Contract for difference',
                desc: 'Provide more than 40 currency pairs, including primary, secondary and other currency pairs',
                imgUrl: require('@/assets/icon_forex-4.svg'),
            },
            {
                title: 'noble metal',
                desc: 'Gold and silver trading is a very attractive alternative investment with high market liquidity',
                imgUrl: require('@/assets/icon_gold.svg'),
            },
            {
                title: 'energy',
                desc: 'It offers three popular trading commodities: Brent crude oil from UK, WTI light crude oil from USA and natural gas from USA',
                imgUrl: require('@/assets/icon_oil.svg'),
            },
            {
                title: 'index',
                desc: 'It provides more than ten kinds of indexes, including multi-national stock market index and US dollar index ',
                imgUrl: require('@/assets/icon_indices-1.svg'),
            },
            {
                title: 'shares',
                desc: 'More than 70 well-known companies listed in the United States and Europe',
                imgUrl: require('@/assets/icon_shares-1.svg'),
            },
        ],
        area: {
            title: 'Global offices on 4 continents',
            desc: 'Across Europe, South America, Asia and Africa',
        },
        honor: {
            title: 'Honorary awards',
            list: [{
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'Best institutional business broker',
                    desc: '2020 Financial Technology Award',
                    desc2: 'International wealth and finance Awards',
                },
                {
                    imgUrl: require('@/assets/Best-Affiliate-Program.png'),
                    title: 'Best Alliance Plan',
                    desc: '2020 DPA broker Award',
                    desc2: 'Fxdailyinfo',
                },
                {
                    imgUrl: require('@/assets/Best-News-Analysis-Provider.png'),
                    title: 'Best news and market analysis provider',
                    desc: '2020 DPA broker Award',
                    desc2: 'Fxdailyinfo',
                },
                {
                    imgUrl: require('@/assets/Best-Online-Trading-Services.png'),
                    title: 'Best online trading service award',
                    desc: '2020advfn international finance Award',
                    desc2: 'ADVFN',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'Best contract for difference broker',
                    desc: 'Rankia.com',
                    desc2: '',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'The most transparent broker',
                    desc: 'World contract for differences award 2019',
                    desc2: 'World contract for differences Award',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'Best education program',
                    desc: 'World contract for differences award 2019',
                    desc2: 'World contract for differences Award',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'The most reliable trading broker',
                    desc: 'UAE Business Awards 2019',
                    desc2: 'Middle East market magazine',
                },
                {
                    imgUrl: require('@/assets/Best-Institutional-Forex-Broker-2020.png'),
                    title: 'Best contract for difference Customer Service Award',
                    desc: '2019 world financial contract for differences Award',
                    desc2: 'World Finance',
                },
            ],
        },
        sysPay: {
            title: 'World famous payment system',
            desc: 'Efficient and automated process, strict audit to ensure the safety of customers investment, diversified channels',
        },
        transaction: {
            title: 'The company is committed to meeting your demand for contracts for differences and foreign exchange transactions',
            desc: ' All transactions involve risks. Your loss may be greater than your income.',
            desca: 'Risk policy',
            text: 'Start real foreign exchange trading',
            btn: 'Open a real account',
        },
        brand: {
            title: ' CompanyNameJapanese   It is a business brand jointly used by many companies, including： ',
            text1: ' CompanyNameJapanese   Is a limited liability company based in Saint Vincent and the Grenadines with company number 333 LLC 2020. The registered address is：1st Floor, First St. Vincent Bank Bldg, James Street, Kingstown, St. Vincent and the Grenadines。 ',
            text2: ' CompanyNameJapanese . Authorized and regulated by the financial conduct authority (FCA), with a license number of 760555. The registered address is：1st Floor, 32 Cornhill, London EC3V 3SG, United Kingdom。 ',
            text3: ' CompanyNameJapanese . Authorized and regulated by the Cyprus securities and Exchange Commission (cysec), license number 285 / 15. The registered address is：159 Leontiou A’ Street, Maryvonne Building Office 204, 3022, Limassol, Cyprus。 ',
            text4: ' CompanyNameJapanese . Authorized and regulated by the Financial Services Commission (FSC) of the Republic of Mauritius, license number c118023331. The registered address is：Suite 207, 2nd Floor, The Catalyst, Silicon Avenue, 40 Cybercity, 72201 Ebène, Republic of Mauritius。 ',
            text5: 'High risk investment warning: foreign exchange and contract for difference transactions are highly speculative and risky, and are not suitable for all investors. You may lose some or all of your investment. Therefore, the amount of your investment should be within your affordability. You should be aware of all the risks associated with margin trading. Please read the complete',
            risk: 'Risk disclosure policy',
            text6: ' Restricted area：CompanyNameJapanese   The website information of is not targeted at the residents of British Columbia, Quebec and Saskatchewan in Canada, Japan, Taiwan, Democratic Peoples Republic of Korea (DPRK), Iran and the United States; And it will not be sent to or used by any person in any country or jurisdiction where the publication or use of the information violates local laws and regulations。 ',
        },
        icp: {
            text1: ' © All rights reserved ',
            text2: 'Qiongicp preparation No. 19002799',
            text3: 'Privacy policy',
            text4: 'Cookies policy',
            text5: 'Terms and conditions',
            text6: 'Website map',
            text7: 'Links',
        },
        bottomSwiper: [{
                text1: 'MetaTrader 4',
                text2: '広く使われている国際的取引ツールとして、高効率実行速度、EA自動化取引に対応可能、優れたグラフ分析機能、数多くの指標自由追加、利益確保と損切りの管理設定、百種以上の便利な取引商品、10万ドルに達するバーチャルアカウント、選択可能な取引端末を取り揃えております。',
            },
            {
                text1: 'CompanyNameJapanese ',
                text2: 'CompanyNameJapanese ——将来性を見極めるユーザーのためのCompanyNameJapanese は、今最も専門的、革新的な取引用ポータブルアプリとして、特化した操作インターフェース、見やすい簡単な管理ツール、はっきりしたグラフ、ディテール拡大縮小などの実用的な専属機能が付き、お客様に喜んでいただけるサービスを提供しております。',
            },
        ],
        topSwiper: [{
                text1: 'プレミアリーグ優勝となるマンチェスターの公式パートナー',
                text2: 'パートナーと手を携え、トップクラスの企業に成長しました',
                text3: '',
            },
            {
                text1: '中央銀行と並ぶものになる市場管理',
                text2: '7部の国際的な管理ライセンス',
                text3: 'お客様の資金が独立に保管され、取引セキュリティも抜群的です。',
            },
            {
                text1: 'WebTrader-オンライン取引',
                text2: '簡単に取扱可能',
                text3: '新規利用者に向けた取引プラットフォーム',
            },
        ],
        headTitle: 'CompanyNameJapanese ',
        btnTran: 'Enter the trading hall',
        Welcome: 'アカウント名',
        openAccount: '実名認証を開設',
        PleaseLogin: 'ログイン',
        lang: '言語',
        tableBox: ['通貨名', '時価', '最安値', '最高値'],
        introduce1: {
            title: '数多くの取引商品',
            data: [{
                    title: '外貨',
                    imgUrl: require('@/assets/pro_1.png'),
                    text1: '60以上の通貨ペア種類',
                    text2: '400:1に達するレバレッジ',
                    text3: '5X24時間T+0上げ相場と下げ相場の両方取引対応',
                },
                {
                    title: '株価指数/株式',
                    imgUrl: require('@/assets/pro_2.png'),
                    text1: '20以上の世界大手株価指数',
                    text2: '70以上の世界著名株式',
                    text3: '取引方式の多様化を図っています',
                },
                {
                    title: '貴金属',
                    imgUrl: require('@/assets/pro_3.png'),
                    text1: '最も大人気なリスク回避商品種類',
                    text2: 'レバレッジが200倍を超えました',
                    text3: '競争力のある変動差',
                },
                {
                    title: 'ETF',
                    imgUrl: require('@/assets/pro_4.png'),
                    text1: '分散型金融取引方式',
                    text2: '数多くの金融集中',
                    text3: '柔軟なレバレッジが20:1に達しました',
                },
            ],
        },
        introduce2: {
            title: 'CompanyNameJapanese が利益を無限大にすることが可能です。',
            content: '管理されているCompanyNameJapanese 会社が、英國金融機構によるヨーロッパの市場規範を厳守し、毎年専門の会計事務所から帳簿審査を受けます。当社は、世界でも有数の金融機構を精選し、お客様が資金をAAグレードと並ぶになる著名国際銀行に保管され、取引セキュリティも抜群的です。関連の金融管理法律によりますと、お客様が機構との資本金が独自に保管されており、所有証拠金がマイナスになった場合、機構が完全に責任を負い、マイナスの分を負担いたします。',
        },
        introduce3: {
            title: '第三者取引観点',
            content: 'CompanyNameJapanese 国際的グループは資本金や実力が信頼でき、数多くの投資や銀行金融業務関係の仕事に15年間携わる専門家によるチームを設立しました。世界トップの金融センターであるロンドンに支社を置くCompanyNameJapanese  (CompanyFullNameEnglish )が、トップグレードの英國金融管理局のFSA（Financial Services Authority）による認証、授権を獲得し、世界各地の華人に向けたゴールデン為替市場や金融業務に取り組んでおり、優れた投資商品をお客様に提供するような経営理念に、金融活動を行うにはぜひ当社をご選びください。',
        },
        learnMove: '詳細を入手  ',
    },
    tabBar: ['取引', '収益宝', 'お問い合わせ', '情報', 'マイ'],
    product: {
        home: 'トップページ',
        Balance: '残高',
        money: '（元）',
        Withdrawal: '出金',
        Inpour: '入金',
        AveragePrice: '平均価格',
        Time: '時間',
        TimeLimit: '時限',
        Min: '分',
        Rate: '収益',
        Loss: '損失',
        Increase: '先高',
        Decrease: '先低',
        O: '寄り付き値',
        H: '最高値',
        L: '最安値',
        C: '大引け値段',
        smallMin: '分のチャート',
        hour: '時のチャート',
        dayMin: '日間のチャート',
        Trend: 'タイムチャート',
        LatestDeal: '最新取引',
        RecentTransaction: '最近取引',
        User: 'ユーザー',
        Contract: '契約',
        Direction: '動向',
        Wager: '手付け金',
        Amount: '金額',
        Profit: '利益損失',
        unfinished: '未完成',
        goLogin: 'ご覧になるのはログインしてください',
        ConfirmPurchase: '買い注文を確認',
        ConfirmAllin: 'すべて注文',
        Buy: '買い注文',
        ExpectedIncome: '収益予測',
        assetType: '資産種類',
        BillingCycle: '決済周期',
        OrderDirection: '注文動向',
        MinimumBalance: '最低残高',
        MinimumPurchase: '買い注文には最小限に',
        upToBuy: '買い注文には最大限に',
        CurrentPrice: '時価',
        Confirm: '確認',
        SuccessfulPurchase: '買い注文終了',
        PleaseWait: 'お待ちください',
        BuyPrice: '買い注文価格',
        EndPrice: '売り注文価格',
        settlementCycle: '決済周期',
        profit: '利益損失',
        second: '秒',
        InvestmentAmount: '投資金額',
        unlimited: '制限無し',
        product: '商品を注文',
        InLiquidation: '売り注文',
        profitText: '収益',
        lossText: '損失 ',
        success: '収益獲得、おめでとうございます ',
    },
    profit: {
        title: '収益宝',
        synopsis: 'あらすじ',
        TotalAmount: '総金額',
        YesterdayP: '昨日収益',
        cumulativeIncome: '合計収益',
        DailyInterest: '日間利子',
        TransferIn: '入金',
        TransferOut: '出金',
        revenueRecord: '収益履歴',
        SingleDay: '一日間収益',
        money: '（元）',
        amount: '金額',
        placeholder: '金額をご入力ください',
        confirm: '確認',
        success: '取扱終了',
        checkTypeAmountWarn: '収益金額がまだ存在し、交換できません',
        checkMsg: '交換品の確認？',
        cancel: 'キャンセル',
        Days:'日'
    },
    news: {
        title: 'ニュース情報',
    },
    me: {
        CreditScore: '信用ポイント',
        Inpour: '入金',
        Withdrawal: '出金',
        Team: '私のチーム',
        AccessLog: '取引履歴',
        FundingDetails: '資金明細',
        HistoryOrder: '注文履歴',
        BankCard: '銀行カード',
        RealName: '実名認証',
        ChangePassword: 'パスワードを再設定',
        wlPassword: '引き出しにはパスワードを再設定',
        Share: 'シェアを選択',
        Language: '言語切り替え',
        Logout: 'ログアウト終了',
        read: '認証終了',
        unread: '認証されていませんでした',
        close: 'クローズ',
        codeText: 'ウィンウィンのためにコードをスキャンしてシェアしてください',
        ShareText: '二次元コードをシェア',
        copyLink: 'シェアリンクをコピー',
        ModifyNickname: 'ニックネームを再設定',
        placeholder: 'ニックネームをご入力ください',
        nickNakeConfirmBtn: '確認',
        nickNakeCancelBtn: 'キャンセル',
        service: 'お問い合わせに連絡してください',
        success: '再設定終了',
    },
    Team: {
        title: '私のチーム',
        subordinateTitle: 'のチーム',
        NickName: 'ニックネーム',
        TeamNum: '下の数',
        ChildsProfit: 'うわまえを撥ねる',
        ParentProfit: '貢献控除',
        TotalProfit: '総利益',
    },
    Inpour: {
        title: '入金',
        Bank: 'ネットバンクでの預金',
        digitalCurrency: 'デジタル通貨',
        SelectAmount: '金額を選択',
        Amount: '預金金額',
        Submit: '提 出',
    },
    rechargeService: {
        title: 'ネットバンクでの預金',
        OpenBank: '預金銀行',
        CardUserName: '口座開設者',
        InpourInformation: '預金情報',
        Amount: '預金金額',
        CardNo: '銀行カード番号.',
        placeholder: '銀行カード番号の入手にはお問い合わせにご連絡ください',
        Support: 'お問い合わせへ',
        Warm: 'ご取扱上の注意：',
        line: '*振込に必要な銀行口座番号情報を受け取った後、ネットバングまたは携帯アプリにより振り込み、履歴情報を提出してから、財務担当者が確認できたら、直ちにご資金項目を追加いたします。',
        line1: '*入金時間を確保するため、同じ銀行での振込をおすすめします。',
        line2: '*ご権益をを確保するため、振込関連領収書は大切に保管してください。',
        line3: '*預金ができなかったり、振込履歴が見つからなかったりする場合、オンラインでのお問い合わせにご連絡ください。',
    },
    Withdrawal: {
        title: '出金',
        titleInfo: '引き出し情報',
        unBind: '銀行カードをバインディングしてください',
        unSWIFTCode: 'SWIFT Codeをバインディングしてください',
        AccountBalance: '口座残高',
        cashBalance: '引き出し可能な金額',
        RrozenAmount: '凍結中金額',
        PRNA: '実名認証を行ってください',
        RNA: '実名認証',
        WithdrawAmount: '引き出し金額',
        WithdrawPassword: '引き出しパスワード',
        Submit: '提出を確認',
        placeholder: '引き出しにはパスワードをご入力ください',
        placeholder1: '引き出し確認 ',
        placeholder2: '％手数料、今回は合計で',
        placeholder3: '（元）',
        cardNo: '引き出しためのカード番号',
        RealName: '実名',
        mobile: '携帯番号',
        IDNumber: '身分証明書番号',
        CertificateAddress: '身分証明書住所',
        CurrentAddress: '現住地',
        tips: 'ご取扱上の注意',
        tipsMessage: '还未设置提现密码，马上前往设置？',
        AmountMessage: '金額をご入力ください',
        PassMessage: '引き出しには正しいパスワードをご入力ください',
        CommissionText: '実際の入金金額は',
        withType: '引き出し方式',
        bankCard: '銀行カード',
        copySuccess: 'コピー成功',
        Network: 'ネットワーク',
        payVoucher: '支払証憑',
        payVoucherMsg: '支払証明書をアップロードしてください',
        shouldPutMsg: '転入すべき'
    },
    Accesslog: {
        title: '取引履歴',
        Inpour: '入金',
        Withdrawal: '出金',
        Amount: '金額',
        type: 'タイプ',
        Time: '時間',
        Status: '状態',
    },
    statusLang: {
        Review: '承認中',
        Success: '終了',
        Fail: '承認されていませんでした',
    },
    FundingDetails: {
        title: '資金明細',
        Upper: '入金',
        Lower: '出金',
        Bet: '投資',
        Win: '売り注文',
        BalanceToFinance: '残高を収益宝に振り込み',
        FinanceeToBalance: '収益宝を残高に振り込み',
        CashProfit: '下級から利益を引き出す',
        money: ' （元）',
    },
    history: {
        title: '注文履歴',
        Contract: '契約',
        Amount: '金額',
        Time: '時間',
        Details: '詳細',
        Profit: '利益損失',
        money: ' （元）',
        unfinished: '未完成',
    },
    bank: {
        title: '銀行カード',
        editTitle: '銀行カードをバインディング',
        accountName: '口座開設者名',
        opening: '開設銀行',
        point: '開設支社',
        card: '銀行カード番号',
        DigitalCurrency: 'デジタル通貨',
        usdt: 'USDTアドレス（記入必要でない）',
        btc: 'BTCアドレス（記入必要でない）',
        eth: 'ETHアドレス（記入必要でない）',
        placeholder1: '口座開設者名をご入力ください',
        placeholder2: '開設銀行をご選択ください',
        placeholder3: '開設支社名をご入力ください',
        placeholder4: '銀行カード番号をご入力ください',
        submit: '確認',
        info: '注意事項：再設定にはお問い合わせにご連絡ください',
    },
    SWIFTCode: {
        CardUserName: '受取人名',
        Area: '受取人所在地',
        DetailArea: '受取人住所',
        OpenBank: '銀行所在地',
        CardNo: '振込口座',
        usdt: 'SWIFT Code番号をご入力ください',
        btc: '振込口座をご入力ください',
        eth: 'ご入力ください',
        placeholder1: '受取人名をご入力ください',
        placeholder2: '受取人所在地をご入力ください',
        placeholder3: '受取人住所をご入力ください',
        placeholder4: '銀行所在地をご入力ください',
        submit: '確 認',
        update: '再設定',
        info: '注意事項：再設定にはお問い合わせにご連絡ください',
    },
    withPass: {
        title: '引き出しパスワード',
        OldPasswordle: '古いパスワード',
        NewPassword: '新しいパスワード',
        ConfirmPassword: 'パスワードをご入力ください',
        placeholder: '古いパスワードをご入力ください',
        placeholder1: '新しいパスワードをご入力ください',
        placeholder2: '新しいパスワードをご確認ください',
        confirm: '再設定をご確認ください',
    },
    authentication: {
        title: '実名認証',
        RealName: '実名',
        Mobile: '携帯番号',
        IDNumber: '身分証明書番号',
        CertificateAddress: '身分証明書住所',
        CurrentAddress: '现住址',
        CertificateZ: '証明書の表面写真です',
        CertificateF: '証明書裏面写真です',
        warnInfo: '＊実名情報の再設定にはできないので、正しくご記入ください',
        Confirm: '提出を確認',
        placeholder: '実名をご入力ください',
        placeholder1: '携帯番号をご入力ください',
        placeholder2: '身分証明書番号をご入力ください',
        placeholder3: '身分証明書住所をご入力ください',
        placeholder4: '現住地をご入力ください',
        message: 'シートを正しくご記入ください',
        message1: '携帯番号を正しくご入力ください',
        success: '実名終了',
        CardType: '証明書タイプ',
        IDCard: '身分証明書',
        License: '運転免許証',
        Passport: 'パスポート',
    },
    login: {
        title: 'ログイン',
        lang: '言語',
        Account: 'アカウント',
        Password: 'パスワード',
        btn: 'ログイン',
        ForgotPassword: 'パスワードの忘れた方',
        NoAccount: 'アカウントが開設されていない方',
        Registration: '登録',
        OtherLogin: 'ほかの登録',
        success: '登録終了',
        fail: 'フォームを改善してください',
        ageent: '読んで同意します',
        ageentTitle: 'サービス契約',
        ageentFail: 'サービス契約書を読んでチェックしてください',
        renzheng: '香港ネットワーク安全局認証',
        beian: '京公網安備110120200001号',
    },
    register: {
        title: '登録',
        lang: '言語',
        Account: 'アカウント',
        PhoneNumber: '携帯番号',
        Password: 'パスワード',
        ConfirmPassword: 'パスワードをご入力ください',
        InvitationCode: '推薦コード',
        btn: '登録',
        haveAccount: 'アカウントの開設した方',
        Login: 'ログイン',
        placeholder: 'アカウント名をご入力ください',
        placeholder1: 'パスワードをご入力ください',
        placeholder2: '二度とパスワードをご入力ください',
        placeholder3: '推薦コードをご入力ください',
        placeholder4: '携帯番号を入力してください',
        success: 'ログイン終了',
        message: 'パスワードは６桁以上をご入力ください',
        message1: '確認パスワードは６桁以上をご入力ください',
        message2: '二回入力いただいたパスワードが違いました',
        VerifCode: '認証コード',
        placeholder5: '認証コードを入力してください',
        Send: '送信',
        SendSuccess: '送信成功',
    },
    public: {
        SubmittedSuccessfully: '提出終了',
        select: 'ご選択ください',
        cancel: 'キャンセル',
        confirm: '確認',
        RefreshSucceeded: '更新終了',
        noMoreData: 'これ以上のデータが見つかりませんでした',
        pullingText: '更新にはスクロールしてください ...',
        ReleaseText: '更新にはリリースしてください ...',
        loadingText: 'ローディング ...',
    },
    xpgyHome: {
        title: '上海心ピン公益基金会',
        articleTitle: '上海心ルパン公益基金会2021年度第1回理事会第5&6次会議円満開催',
        articleContent1: '12月2日午後、上海555ビル12階の会議室にて、上海心蘋公益基金会2021年度第1回理事会第5&6回会議(以下「理事会」と略称します)が開催されました。上海心蘋公益基金会理事長の頼穎生、副理事長の呉偉、理事の王国民、何非方が出席し、監事の申屠媛艶、金幸福が出席し、周曄如、張翊、許新蘋が会議に列席しました。',
        articleContent2: '上海心蘋公益基金会は上海民政局の指導と理事会の指導の下、4つのプロジェクトを完成し、2つの施設サービス所でサービスを開始します。また、「上海真愛夢想基金会」と協力して共同募集を行います。同時に財団の公式サイトの構築、3A社会組織の等級評価、非営利団体の免税資格認定を着実に進め、人員配置とチーム構成を徐々に改善し、2022年にはチームの総合的な業務能力と専門技能を向上させ、財団の公益サービスの目的に合致する対象者に積極的に支援を提供し、情報開示の仕事をさらにしっかりと行います。資金予算の仕事をしっかりと行い、管理費の合理的な使用を確保し、各指標は基金会に対する主管部門の要求を達成します。理事会と監査役会は今回の会議で事務局から2021年度財団活動状況と2022年活動計画の報告を聞き、財団の活動成果を肯定的に評価しました。',
        articleContent3: '理事会は理事会と監事会の調整事項を審議して可決しました。周曄さんは上海心蘋公益基金会の秘書長、張翊女史は副秘書長に任命しました。財団規約の改正が審議され承認され、財団規約が検討され審議されました。',
        articleContent4: '会議で理事たちが次々と献策しました。心蘋基金会はより多くの専門家を吸収し、プロジェクトの現地化を行い、コミュニティを深耕し、上海でしっかりとしたプロジェクト基盤を作り、オンライン募金の長所を発揮するだけでなく、より多くのオフライン企業を発掘して寄付を行うべきだと提案しました。',
        articleContent5: '最後に、理事長の頼穎生は、次の仕事では、自分はより多くの企業資源とドッキングし、基金会のプロジェクトスペースを増やすための勧誘資金を強化する一方で、スタッフの建設を強化し、チームの能力のトレーニングと雰囲気の構築を手配し、チームの結束力を固めるために、基金会の仕事を、さらに上海で活発な慈善の雰囲気にしたいと述べた。業界意識を高め、資源とプラットフォームの優位性を十分に発揮し、基金自体の質の高い発展を共に保障します。',
        footer1: '上海心ピン公益基金会',
        footer2: '滬ICP備2021034925号',
        footer3: '滬公網安備31010602006588号',
        footer4: '技術支持:杭州映派科技有限公司',
    },
}